import React from 'react'
import { observer } from 'mobx-react'
import SidePanel from '../Components/SidePanel'
import Logo from '../Components/SidePanel/logo'
import PropTypes from 'prop-types'
import Tooltip from '../Components/Tooltip/Tooltip'
import Button from '../Components/Button'
import Select from '../Components/Select'
import Loading from '../Components/Spinner'
import TempResponsiveScreen from '../Components/TempResponsiveScreen'
import ThankYou from '../Components/TempResponsiveScreen/ThankYou'
import Checkbox from '../Components/Checkbox'
import NotFoundPage from '../Components/NotFound/NotFoundPage'
import { expertApi, massProcessingApi } from '@atheneum/api'
import _ from 'lodash'
import axios from 'axios'

const axiosInstance = axios.create({
  timeout: 25000,
  responseType: 'json',
})

const reasons = [
  'Compensation is too low given my level of expertise',
  'I have restrictions or conflicts that prevent me from participating',
  'I have heard negative things about Atheneum',
  'I already signed up with a different expert network',
  'Offers are not relevant to my field of expertise',
  'Your emails are unprofessional or sound like spam',
  'I do not want to be contacted',
  'Other',
]

const contactTypes = [
  { type: 'all', title: 'All', description: 'Remove me from all emails', allowed: false },
  { type: 'marketing', title: 'Atheneum news', description: '', allowed: false, disabled: false },
  {
    type: 'survey',
    title: 'Compensated On-line Surveys',
    description: '',
    allowed: false,
    disabled: false,
  },
  {
    type: 'consultation',
    title: 'Compensated Consultations',
    description: '',
    allowed: false,
    disabled: false,
  },
  {
    type: 'sms',
    title: 'Remove from mobile app SMS & Email',
    description: '',
    allowed: false,
    disabled: false,
  },
]

const initialState = {
  screen: null,
  currentLanguage: null,
  loading: true,
  error: false,
  contactTypes: contactTypes,
  contactTypeAll: Object.assign(
    {},
    contactTypes.find((contactType) => contactType.type === 'all'),
  ),
  contacts: null,
  reasons,
  selectedReason: '',
  currentQuestionIndex: 0,
  user: null,
  massProcessingHash: false,
  email: '',
}

class ExpertUnsubscribe extends TempResponsiveScreen {
  static propTypes = {
    growlStore: PropTypes.object,
  }

  constructor(props) {
    super(props)
    const { match } = props
    const uuid = match.params.hash
    if (uuid) {
      this.loadContactTypes(uuid)
    }
    this.setScreen = this.setScreen.bind(this)
    this.setThankYouScreen = this.setThankYouScreen.bind(this)

    if (!uuid) {
      this.state = {
        ...this.state,
        massProcessingHash: false,
        loading: false,
      }
    }
  }
  state = initialState

  loadContactTypes = (uuid) => {
    return Promise.all([expertApi.getContactTypes(uuid), expertApi.getUnsubscribeContacts(uuid)])
      .then(([contactTypes, contacts]) => {
        const filteredContacts = contacts.filter((x) => x.type === 'email')
        this.setState({
          contacts: filteredContacts,
          contactTypes: this.contactTypesWithDisabled(contactTypes),
          massProcessingHash: true,
          loading: false,
          contactTypeAll: Object.assign(
            {},
            contactTypes.find((contactType) => contactType.type === 'all'),
          ),
        })
      })
      .catch((err) => {
        console.error(err)
        this.setState({ massProcessingHash: false, loading: false })
      })
  }

  contactTypesWithDisabled = (contactTypes) => {
    const findContactTypeAll = contactTypes.find((contactType) => contactType.type === 'all')

    const updatedContactTypes = contactTypes.map((contactType) => {
      if (contactType.type !== 'all') {
        contactType.disabled = findContactTypeAll.allowed
      }

      return contactType
    })

    return updatedContactTypes
  }

  forceAllowedIfContactTypeAllExists = (contactTypes) => {
    const findContactTypeAll = contactTypes.find((type) => type.type === 'all')

    if (findContactTypeAll.allowed) {
      contactTypes = contactTypes.map((type) => {
        type.allowed = findContactTypeAll.allowed
        return type
      })
    }

    return contactTypes
  }

  resetState() {
    this.setState(initialState)
  }

  setScreen(screen) {
    this.setState({ screen })
  }

  stringifyContactTypes(contactTypes) {
    return contactTypes.map((contactType) => contactType.type).join(', ')
  }

  setThankYouScreen(response) {
    const { contactTypesToUpdate, blockedContactTypes } = response

    const baseString = 'Your mail preferences have'
    const thankYouMessage = `${baseString} have been configured`

    const contactTypesResubscribe = contactTypesToUpdate.map((contactType) => {
      contactType.allowed = !contactType.allowed
      return contactType
    })

    this.setState({
      thankYouMessage,
      contactTypesResubscribe,
      blockedContactTypes,
      loading: false,
    })

    this.setScreen('thankYou')
  }

  unsubscribe = async (payload, action) => {
    try {
      const response = await expertApi.unsubscribe(payload)
      const { contactTypesToUpdate, blockedContactTypes } = response

      if (!contactTypesToUpdate) {
        return this.setThankYouScreen(
          { contactTypesToUpdate: [], blockedContactTypes, noContactTypesToUpdate: true },
          action,
        )
      }

      this.props.growlStore.showSuccess(action)
      return this.setThankYouScreen(response)
    } catch (error) {
      this.props.growlStore.showError(error)
    }
  }

  resubscribe = (uuid) => {
    this.setState({ loading: true })
    const { contactTypesResubscribe } = this.state

    const payload = {
      uuid,
      contactTypes: contactTypesResubscribe,
      reason: this.state.customReason || this.state.selectedReason,
      type: 'resubscribe',
    }

    this.unsubscribe(payload, 'Resubscribed')
  }
  renderToolTipContent = () => {
    return (
      <div>
        <span>Please select an option from above</span>
      </div>
    )
  }
  areAllContactTypesUnselected = () => {
    return this.state.contactTypes.every((t) => !t.allowed)
  }
  onContactTypeClick = (type) => {
    const contactTypes = this.state.contactTypes.map((contactType) => {
      if (contactType.type === type) {
        contactType.allowed = !contactType.allowed
      }

      return contactType
    })

    const contactTypesWithDisabled = this.contactTypesWithDisabled(contactTypes)
    this.setState({ contactTypes: contactTypesWithDisabled })
  }

  onReasonChange = (e) => {
    this.setState({ selectedReason: e.target.value })
  }

  onCustomReasonChange = (e) => {
    this.setState({ customReason: e.target.value })
  }

  onEmailChange = (e) => {
    this.setState({ email: e.target.value })
  }

  onSubmit = async (uuid, actionid) => {
    this.setState({ loading: true })
    const { customReason, selectedReason, contactTypes, contactTypeAll } = this.state
    let reason = ''
    if (!customReason && !selectedReason) {
      reason = 'Expert unsubscribed without selecting any reason (Comment by Expert)'
      //update selected reason so that we can use it in resubscribe action
      this.setState({ selectedReason: reason })
    } else {
      reason = (customReason || selectedReason) + ' (Comment by Expert)'
    }
    if (uuid) {
      const cleanContactTypes = contactTypes.map((contactType) => {
        delete contactType.checkboxMarkup
        return contactType
      })

      const findContactTypeAll = cleanContactTypes.find((contactType) => contactType.type === 'all')

      const readyContactTypes =
        findContactTypeAll.allowed === contactTypeAll.allowed
          ? cleanContactTypes.filter((contactType) => contactType.type !== 'all')
          : cleanContactTypes.map((contactType) => {
              if (contactType.type === 'all') {
                contactType.allowed = findContactTypeAll.allowed
              }
              return contactType
            })
      const payload = {
        uuid,
        contactTypes: readyContactTypes,
        reason: reason,
        type: 'unsubscribe',
      }

      if (actionid) {
        await massProcessingApi.updateActionInterest({
          id: actionid,
          interested: false,
          unsubscribed: true,
          reason: reason,
        })
      }

      this.unsubscribe(payload, 'Unsubscribed')
    } else {
      if (!isValidEmail(this.state.email)) {
        alert('Email is not valid')
      } else {
        this.setState({ loading: false })
        let selected = ''
        if (this.state.contactTypes.find((contact) => contact.type === 'all' && contact.allowed)) {
          selected =
            'Atheneum news, Compensated On-line Surveys, Compensated Consultations and Remove from mobile app SMS & Email'
        } else {
          const filteredContacts = this.state.contactTypes
            .filter((contact) => contact.type !== 'all' && contact.allowed)
            .map((contact) => contact.title)
          selected = filteredContacts.join(', ').replace(/, ([^,]*)$/, ' and $1')
        }
        await axiosInstance.post('/api/extern/unsubscribe-email', { reason, selected, email: this.state.email })
        this.setScreen('thankYou')
      }
    }
  }

  render() {
    const { match } = this.props
    const uuid = match.params.hash
    const actionid = match.params.actionid
    let contactTypes
    let dinstictContacts
    if (uuid) {
      if (this.state.loading) {
        return <Loading />
      }

      if (!this.state.massProcessingHash) {
        return <NotFoundPage />
      }
    }
    const pageTitle = 'Are you sure you want to unsubscribe from all of our opportunities?'

    const screens = {
      thankYou: (
        <ThankYou
          pageTitle=""
          buttonVisible={false}
          accept={() => this.resetState()}
          header={<ThankYouHeader isGenericLink={!Boolean(uuid)} />}
          formInnerStyle={{
            maxWidth: '530px',
            textAlign: 'center',
            margin: '0 auto',
          }}
          content={
            <ThankYouContent
              message={this.state.thankYouMessage}
              blockedContactTypes={this.state.blockedContactTypes}
              onClick={() => this.resubscribe(uuid)}
            />
          }
        />
      ),
    }

    if (this.state.screen) {
      return screens[this.state.screen]
    }

    contactTypes = this.state.contactTypes.map((type) => {
      type.checkboxMarkup = type.disabled ? (
        ''
      ) : (
        <Checkbox
          className="single__checkbox"
          inlineStyle={{ borderColor: '#33343a' }}
          onChange={() => this.onContactTypeClick(type.type)}
          value={type.allowed}
          disabled={type.disabled}
        />
      )
      return type
    })

    dinstictContacts = _.sortBy(_.uniqBy(this.state.contacts, 'value'), 'value')
    return (
      <div className="expert-compliance">
        <SidePanel />
        <div className="form-expert-compliance" style={{ display: 'flex', alignItems: 'center' }}>
          <Logo className="expert-compliance-form-logo" />
          <div
            className="form-inner"
            style={{
              maxWidth: '530px',
              borderColor: '#dfdfdf',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}
          >
            <div className="unsubscribe-box">
              <h1 className="expert-form-login__title">{pageTitle}</h1>
              <div>
                Atheneum offers a wide variety of exclusive projects in demand by your peers, including phone
                consultations, quick surveys and conference appearances.
              </div>
              <div>Your expertise is highly valued and would be well compensated. See atheneum.ai/network/</div>
              <ContactTypes
                label="Please adjust your email preferences below"
                types={contactTypes}
                onContactTypeClick={this.onContactTypeClick}
              />
              {uuid ? (
                <div className="reason-container">
                  <div className="title">Unsubscribe emails:</div>
                  <ul>
                    {dinstictContacts.map((x) => (
                      <li>{x.value}</li>
                    ))}
                  </ul>
                </div>
              ) : (
                <div className="reason-container">
                  <div className="title">Unsubscribe email:</div>
                  <input style={{ width: '465px' }} onChange={this.onEmailChange} />
                </div>
              )}
              <div className="reason-container">
                <div className="title">Please let us know why you want to unsubscribe:</div>
                <Select
                  placeholder="Please select reason"
                  value={this.state.selectedReason}
                  options={this.state.reasons}
                  onChange={this.onReasonChange}
                  disabled={false}
                />
                {this.state.selectedReason === 'Other' && (
                  <textarea className="custom-reason" onChange={this.onCustomReasonChange}></textarea>
                )}
              </div>
            </div>
            <Tooltip
              tooltip={this.renderToolTipContent()}
              disabled={!this.areAllContactTypesUnselected()}
              placement="top"
            >
              <div style={{ cursor: 'not-allowed' }}>
                <Button
                  style={this.areAllContactTypesUnselected() ? { pointerEvents: 'none' } : {}}
                  primary
                  submit
                  onClick={() => this.onSubmit(uuid, actionid)}
                  disabled={this.areAllContactTypesUnselected()}
                >
                  Unsubscribe Me
                </Button>
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
    )
  }
}

const ContactTypes = ({ label, types, onContactTypeClick }) => {
  const contactTypesWithoutAll = types.filter((type) => type.type !== 'all')
  const findContactTypeAll = types.find((type) => type.type === 'all')

  return (
    <div className="contact-types">
      <div className="label">{label}</div>
      <div className="list">
        {contactTypesWithoutAll.map((type, index) => {
          return (
            <div key={index} className="item">
              <div className="basic-info">
                <div className="title">{type.title}</div>
                {type.description && <div className="description">{type.description}</div>}
              </div>
              {type.checkboxMarkup}
            </div>
          )
        })}
      </div>
      <div className="toggle-contact-types">
        <div className="title">{findContactTypeAll.description}</div>
        <Checkbox
          inlineStyle={{ borderColor: '#33343a' }}
          className="single__checkbox"
          onChange={() => onContactTypeClick(findContactTypeAll.type)}
          value={findContactTypeAll.allowed}
        />
      </div>
    </div>
  )
}

const ThankYouHeader = ({ isGenericLink }) => {
  return (
    <div className="thankyou-header">
      <div className="checkmark">
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
        </svg>
      </div>
      <div className="title">
        {!isGenericLink ? 'Completed' : 'Your request to unsubscribe has been received. We will process it shortly.'}
      </div>
    </div>
  )
}

const isValidEmail = (email) => {
  return /\S+@\S+\.\S+/.test(email)
}

const ThankYouContent = ({ message, blockedContactTypes, onClick }) => {
  return (
    <div>
      {message}
      {blockedContactTypes && blockedContactTypes.length > 0 ? (
        <div
          className="subscribe-again"
          style={{
            marginTop: '75px',
            borderTop: '1px solid #707070',
            display: 'flex',
            paddingTop: '20px',
          }}
        >
          <span style={{ marginRight: '10px' }}>Unsubscribe by accident?</span>
          <span
            className="text-link"
            style={{
              fontWeight: 'bold',
              textDecoration: 'underline',
              color: '#3A86BF',
              cursor: 'pointer',
            }}
            onClick={onClick}
          >
            Subscribe again
          </span>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default observer(ExpertUnsubscribe)
